















































import Vue from 'vue';

export default Vue.extend({
  name: 'DepartmentsDropdown',
  props: ['selectedFranceDepartments', 'selectedBelgiumDepartments', 'toggleBelgium'],
  data() {
    return {
      isOpen: false,
      dataFrance: [
        {
          uniqueId: '01',
          value: '01',
          name: 'Ain',
        },
        {
          uniqueId: '02',
          value: '02',
          name: 'Aisne',
        },
        {
          uniqueId: '2A',
          value: '2A',
          name: 'Corse-du-Sud',
        },
        {
          uniqueId: '2B',
          value: '2B',
          name: 'Haute-Corse',
        },
        {
          uniqueId: '03',
          value: '03',
          name: 'Allier',
        },
        {
          uniqueId: '04',
          value: '04',
          name: 'Alpes-de-Haute-Provence',
        },
        {
          uniqueId: '05',
          value: '05',
          name: 'Hautes-Alpes',
        },
        {
          uniqueId: '06',
          value: '06',
          name: 'Alpes-Maritimes',
        },
        {
          uniqueId: '07',
          value: '07',
          name: 'Ardèche',
        },
        {
          uniqueId: '08',
          value: '08',
          name: 'Ardennes',
        },
        {
          uniqueId: '09',
          value: '09',
          name: 'Ariège',
        },
        {
          uniqueId: '10',
          value: '10',
          name: 'Aube',
        },
        {
          uniqueId: '11',
          value: '11',
          name: 'Aude',
        },
        {
          uniqueId: '12',
          value: '12',
          name: 'Aveyron',
        },
        {
          uniqueId: '13',
          value: '13',
          name: 'Bouches-du-Rhône',
        },
        {
          uniqueId: '14',
          value: '14',
          name: 'Calvados',
        },
        {
          uniqueId: '15',
          value: '15',
          name: 'Cantal',
        },
        {
          uniqueId: '16',
          value: '16',
          name: 'Charente',
        },
        {
          uniqueId: '17',
          value: '17',
          name: 'Charente-Maritime',
        },
        {
          uniqueId: '18',
          value: '18',
          name: 'Cher',
        },
        {
          uniqueId: '19',
          value: '19',
          name: 'Corrèze',
        },
        {
          uniqueId: '21',
          value: '21',
          name: "Côte-d'Or",
        },
        {
          uniqueId: '22',
          value: '22',
          name: "Côtes-d'Armor",
        },
        {
          uniqueId: '23',
          value: '23',
          name: 'Creuse',
        },
        {
          uniqueId: '24',
          value: '24',
          name: 'Dordogne',
        },
        {
          uniqueId: '25',
          value: '25',
          name: 'Doubs',
        },
        {
          uniqueId: '26',
          value: '26',
          name: 'Drôme',
        },
        {
          uniqueId: '27',
          value: '27',
          name: 'Eure',
        },
        {
          uniqueId: '28',
          value: '28',
          name: 'Eure-et-Loir',
        },
        {
          uniqueId: '29',
          value: '29',
          name: 'Finistère',
        },
        {
          uniqueId: '30',
          value: '30',
          name: 'Gard',
        },
        {
          uniqueId: '31',
          value: '31',
          name: 'Haute-Garonne',
        },
        {
          uniqueId: '32',
          value: '32',
          name: 'Gers',
        },
        {
          uniqueId: '33',
          value: '33',
          name: 'Gironde',
        },
        {
          uniqueId: '34',
          value: '34',
          name: 'Hérault',
        },
        {
          uniqueId: '35',
          value: '35',
          name: 'Ille-et-Vilaine',
        },
        {
          uniqueId: '36',
          value: '36',
          name: 'Indre',
        },
        {
          uniqueId: '37',
          value: '37',
          name: 'Indre-et-Loire',
        },
        {
          uniqueId: '38',
          value: '38',
          name: 'Isère',
        },
        {
          uniqueId: '39',
          value: '39',
          name: 'Jura',
        },
        {
          uniqueId: '40',
          value: '40',
          name: 'Landes',
        },
        {
          uniqueId: '41',
          value: '41',
          name: 'Loir-et-Cher',
        },
        {
          uniqueId: '42',
          value: '42',
          name: 'Loire',
        },
        {
          uniqueId: '43',
          value: '43',
          name: 'Haute-Loire',
        },
        {
          uniqueId: '44',
          value: '44',
          name: 'Loire-Atlantique',
        },
        {
          uniqueId: '45',
          value: '45',
          name: 'Loiret',
        },
        {
          uniqueId: '46',
          value: '46',
          name: 'Lot',
        },
        {
          uniqueId: '47',
          value: '47',
          name: 'Lot-et-Garonne',
        },
        {
          uniqueId: '48',
          value: '48',
          name: 'Lozère',
        },
        {
          uniqueId: '49',
          value: '49',
          name: 'Maine-et-Loire',
        },
        {
          uniqueId: '50',
          value: '50',
          name: 'Manche',
        },
        {
          uniqueId: '51',
          value: '51',
          name: 'Marne',
        },
        {
          uniqueId: '52',
          value: '52',
          name: 'Haute-Marne',
        },
        {
          uniqueId: '53',
          value: '53',
          name: 'Mayenne',
        },
        {
          uniqueId: '54',
          value: '54',
          name: 'Meurthe-et-Moselle',
        },
        {
          uniqueId: '55',
          value: '55',
          name: 'Meuse',
        },
        {
          uniqueId: '56',
          value: '56',
          name: 'Morbihan',
        },
        {
          uniqueId: '57',
          value: '57',
          name: 'Moselle',
        },
        {
          uniqueId: '58',
          value: '58',
          name: 'Nièvre',
        },
        {
          uniqueId: '59',
          value: '59',
          name: 'Nord',
        },
        {
          uniqueId: '60',
          value: '60',
          name: 'Oise',
        },
        {
          uniqueId: '61',
          value: '61',
          name: 'Orne',
        },
        {
          uniqueId: '62',
          value: '62',
          name: 'Pas-de-Calais',
        },
        {
          uniqueId: '63',
          value: '63',
          name: 'Puy-de-Dôme',
        },
        {
          uniqueId: '64',
          value: '64',
          name: 'Pyrénées-Atlantiques',
        },
        {
          uniqueId: '65',
          value: '65',
          name: 'Hautes-Pyrénées',
        },
        {
          uniqueId: '66',
          value: '66',
          name: 'Pyrénées-Orientales',
        },
        {
          uniqueId: '67',
          value: '67',
          name: 'Bas-Rhin',
        },
        {
          uniqueId: '68',
          value: '68',
          name: 'Haut-Rhin',
        },
        {
          uniqueId: '69',
          value: '69',
          name: 'Rhône',
        },
        {
          uniqueId: '70',
          value: '70',
          name: 'Haute-Saône',
        },
        {
          uniqueId: '71',
          value: '71',
          name: 'Saône-et-Loire',
        },
        {
          uniqueId: '72',
          value: '72',
          name: 'Sarthe',
        },
        {
          uniqueId: '73',
          value: '73',
          name: 'Savoie',
        },
        {
          uniqueId: '74',
          value: '74',
          name: 'Haute-Savoie',
        },
        {
          uniqueId: '75',
          value: '75',
          name: 'Paris',
        },
        {
          uniqueId: '76',
          value: '76',
          name: 'Seine-Maritime',
        },
        {
          uniqueId: '77',
          value: '77',
          name: 'Seine-et-Marne',
        },
        {
          uniqueId: '78',
          value: '78',
          name: 'Yvelines',
        },
        {
          uniqueId: '79',
          value: '79',
          name: 'Deux-Sèvres',
        },
        {
          uniqueId: '80',
          value: '80',
          name: 'Somme',
        },
        {
          uniqueId: '81',
          value: '81',
          name: 'Tarn',
        },
        {
          uniqueId: '82',
          value: '82',
          name: 'Tarn-et-Garonne',
        },
        {
          uniqueId: '83',
          value: '83',
          name: 'Var',
        },
        {
          uniqueId: '84',
          value: '84',
          name: 'Vaucluse',
        },
        {
          uniqueId: '85',
          value: '85',
          name: 'Vendée',
        },
        {
          uniqueId: '86',
          value: '86',
          name: 'Vienne',
        },
        {
          uniqueId: '87',
          value: '87',
          name: 'Haute-Vienne',
        },
        {
          uniqueId: '88',
          value: '88',
          name: 'Vosges',
        },
        {
          uniqueId: '89',
          value: '89',
          name: 'Yonne',
        },
        {
          uniqueId: '90',
          value: '90',
          name: 'Territoire de Belfort',
        },
        {
          uniqueId: '91',
          value: '91',
          name: 'Essonne',
        },
        {
          uniqueId: '92',
          value: '92',
          name: 'Hauts-de-Seine',
        },
        {
          uniqueId: '93',
          value: '93',
          name: 'Seine-Saint-Denis',
        },
        {
          uniqueId: '94',
          value: '94',
          name: 'Val-de-Marne',
        },
        {
          uniqueId: '95',
          value: '95',
          name: "Val-d'Oise",
        },
      ],
      dataBelgium: [
        {
          uniqueId: 301,
          value: 301,
          name: 'Bruxelles',
        },
        {
          uniqueId: 302,
          value: 302,
          name: 'Anvers',
        },
        {
          uniqueId: 303,
          value: 303,
          name: 'Brabant flamand',
        },
        {
          uniqueId: 304,
          value: 304,
          name: 'Brabant wallon',
        },
        {
          uniqueId: 305,
          value: 305,
          name: 'Flandre occidentale',
        },
        {
          uniqueId: 306,
          value: 306,
          name: 'Flandre orientale',
        },
        {
          uniqueId: 307,
          value: 307,
          name: 'Hainaut',
        },
        {
          uniqueId: 308,
          value: 308,
          name: 'Liège',
        },
        {
          uniqueId: 309,
          value: 309,
          name: 'Limbourg',
        },
        {
          uniqueId: 310,
          value: 310,
          name: 'Province du Luxembourg',
        },
        {
          uniqueId: 311,
          value: 311,
          name: 'Namur',
        },
      ],
    };
  },
  methods: {
    selectedItem(selectedItem: any, isFrance: boolean) {
      this.$emit('departmentClicked', {
        ...selectedItem,
        isFrance: isFrance,
      });
      setTimeout(() => {
        this.isOpen = false;
      }, 0);
    },
    closeDropdown(e: MouseEvent) {
      if (this.$refs.dropdown) {
        let targetElement: Node = e.target as Node;
        if (!(this.$refs.dropdown as any).contains(targetElement) && this.isOpen) {
          this.isOpen = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown.bind(this));
  },
  activated() {
    document.addEventListener('click', this.closeDropdown.bind(this));
  },
  destroyed() {
    document.removeEventListener('click', this.closeDropdown.bind(this));
  },
  deactivated() {
    document.removeEventListener('click', this.closeDropdown.bind(this));
  },
});
