










import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
    name: 'App',
    components: {
        Header,
        Footer,
    },
    metaInfo: {
        title: 'Urgence Maison de repos',
        // titleTemplate: '%s | Maison de retraite',
        htmlAttrs: {
            lang: 'fr',
        },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content: 'An example Vue application with vue-meta.',
            },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'robots', content: 'index,follow' },
            { name: 'googlebot', content: 'index,follow' },
            { name: 'google', content: 'nositelinkssearchbox' },
        ],
    },
};
