
































































import DepartmentsDropdown from '@/views/Home/components/DepartmentsDropdown.vue';
import Map from '@/components/Map.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'Main',
    components: {
        Map,
        DepartmentsDropdown,
    },
    data() {
        return {
            maxSelectedDepartments: 8, //Number maximum of departments that can be selected
            toggleBelgium: true, //Toggle beetween france or belgium map
            selectedDepartments: [] as any[],
        };
    },
    computed: {
        disabledGoToForm: function (): any {
            return !this.selectedFranceDepartments.length && !this.selectedBelgiumDepartments.length;
        },
        selectedFranceDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
        selectedBelgiumDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (!department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
    },
    methods: {
        departmentClicked(selectedDepartment: any) {
            if (
                selectedDepartment.isFrance &&
                this.selectedFranceDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }

            if (
                !selectedDepartment.isFrance &&
                this.selectedBelgiumDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }

            if (this.selectedDepartments.length < this.maxSelectedDepartments) {
                this.selectedDepartments.push(selectedDepartment);
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }
        },
        gotToForm() {
            let departmentsSelected: any = this.selectedDepartments.map((d) => `${d.name}`);
            this.$router.push({
                name: 'Form',
                params: { departments: departmentsSelected.join(',') },
            });
        },
    },
    mounted() {},
});
