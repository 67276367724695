








































































































































import Vue from 'vue';
import RelationDropdown from './components/RelationDropdown.vue';
import Modal from '@/components/Modal.vue';
import Axios from 'axios';

export default Vue.extend({
    watch: undefined,
    name: 'Form',
    components: {
        RelationDropdown,
        Modal,
    },
    data() {
        return {
            lastName: '',
            name: '',
            email: '',
            phone: '',
            conditions: false,
            relation: null,
            unsentForm: true,
            unsetFormCallback: null as any,
            showModalUnsentForm: false,
            showModalConditions: false,
            invalidField: [] as any,
        };
    },
    computed: {
        disabledSendForm: function (): any {
            return (
                !this.lastName ||
                !this.name ||
                !this.email ||
                !this.phone ||
                !this.conditions ||
                !this.relation ||
                !this.$route.params.departments ||
                !this.$route.params.departments.length
            );
        },
    },
    methods: {
        checkIfFormIsValid() {
            this.invalidField = [];
            if (!this.disabledSendForm) {
                let frenchRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
                let belgiumRegex = /^(?:(?:\+|00)(32|30)|0)[1-9]{8,12}$/;

                if (!this.phone.match(frenchRegex) && !this.phone.match(belgiumRegex)) {
                    this.invalidField.push('phone');
                }
                let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.email.match(emailRegex)) {
                    this.invalidField.push('email');
                }

                return !this.invalidField.length;
            }

            return false;
        },
        async sendForm() {
            if (this.checkIfFormIsValid()) {
                let url = 'https://urgence-maison-de-retraite.fr/apiForm.php';
                let ipUser = await Axios.get('https://api.ipify.org');

                this.$gtag.event('conversion', { send_to: 'AW-963583816/TY6uCLKO37IDEMi-vMsD' });

                Axios.get(url, {
                    params: {
                        inputDep: this.$route.params.departments, // (1) 00 - Name (2+) 01 - Name1,02 - Name2
                        cnom: this.lastName,
                        cprenom: this.name,
                        cemail: this.email,
                        contactlink: this.relation, // relation.name
                        cphone: this.phone,
                        consentementVal: this.conditions ? 1 : 0, // 0 or 1
                        source: 'GUBF107',
                        emailAR: 'lead69',
                        userip: ipUser.data, // get IP from client
                    },
                }).then(
                    () => {
                        //Success
                        console.log('Success');

                        this.unsentForm = false;
                        this.$router.push({
                            name: 'ThankYou',
                            params: { lastName: this.lastName, name: this.name },
                        });
                    },
                    () => {
                        //Error
                        console.log('Error');
                    }
                );
            }
        },
        closeUnsentForm() {
            this.showModalUnsentForm = false;
            this.unsetFormCallback = false;
        },
    },
    beforeCreate(): any {
        if (!this.$route.params.departments || !this.$route.params.departments.length) {
            this.$router.push({ name: 'Home' }).catch(() => {});
        }
    },
    beforeRouteLeave(to, from, next) {
        this.showModalUnsentForm = true;
        if (this.unsentForm) {
            this.unsetFormCallback = () => next();
            next(false);
        } else {
            next();
        }
    },
});
